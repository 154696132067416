/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
 import md5 from 'md5';
 import axios from 'axios';import QS from 'qs';


axios.defaults.baseURL = '/api';
 // // 环境的切换
 // if (process.env.NODE_ENV == 'development') {
 //     axios.defaults.baseURL = '/api';
 // } else if (process.env.NODE_ENV == 'debug') {
 //     axios.defaults.baseURL = 'http://xjr.ontheway168.cn/api/';
 // } else if (process.env.NODE_ENV == 'production') {
 //     axios.defaults.baseURL = 'http://xjr.ontheway168.cn/api/';//测试
 //     // axios.defaults.baseURL = '';//预发
 //     // axios.defaults.baseURL = '';//正式
 // }

 // 请求超时时间
 axios.defaults.timeout = 10000;

 // post请求头

 axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
 const u = navigator.userAgent;
 // android终端
 const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
 // ios终端
 const isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
 let p1 = '';
 if (isIos) {
     axios.defaults.headers.common['platform'] = 10
     p1 = "10";
 } else if (isAndroid) {
     axios.defaults.headers.common['platform'] = 20
     p1 = "20";
 }else {//这里是因为后端预览商品需要用
     p1 = '10';
     axios.defaults.headers.common['platform'] = 10;
 }
 // 请求拦截器
 axios.interceptors.request.use(
     config => {
         // 每次发送请求之前判断是否存在token，如果存在，则统一在https请求的header都加上token，不用每次请求都手动添加了
         // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
         const token = sessionStorage.getItem('token');
         var time = parseInt(new Date().getTime()/1000);
         var encryption = md5(time+p1+'haimaproject');
         config.headers.datetime=time;
         config.headers.encryption=encryption;
         if(token) {
             config.headers.Authorization=token;
         }
         return config;
     },
     error => {
         return Promise.error(error);
     })

 // 响应拦截器
 axios.interceptors.response.use(
     response => {
         if (response.status === 200) {
             return Promise.resolve(response);
         } else {
             return Promise.reject(response);
         }
     },
     // 服务器状态码不是200的情况
     error => {
         if (error.response.status) {
             return Promise.reject(error.response);
         }
     }
 );
 /**
  * get方法，对应get请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function get(url, params){
     return new Promise((resolve, reject) =>{
         axios.get(url, {
             params: params
         })
         .then(res => {
             resolve(res.data);
         })
         .catch(err => {
             reject(err.data)
         })
     });
 }
 /**
  * post方法，对应post请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function post(url, params) {
     return new Promise((resolve, reject) => {
         axios.post(url, QS.stringify(params))
         .then(res => {
             resolve(res.data);
         })
         .catch(err => {
             reject(err.data)
         })
     });
 }
