import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{
      title:'首页'
    },
    component: Home
  },
  {
    path: '/commonPage',//公共页面详情
    name: 'commonPage',
    meta:{
      title:'详情'
    },
    component: () => import( '../pages/commonPage.vue')
  },
  {
    path: '/sharePage',//分享页面详情
    name: 'sharePage',
    meta:{
      title:'星家人'
    },
    component: () => import( '../pages/sharePage.vue')
  },
  {
    path: '/xinjiaren/detail',//详情
    name: 'detail',
    meta:{
      title:'星家人'
    },
    component: () => import( '../pages/xinjiaren/detail.vue')
  }, {
    path: '/test',//详情
    name: 'detail',
    meta:{
      title:'星家人'
    },
    component: () => import( '../pages/test.vue')
  },

]

const router = new VueRouter({
  routes
})

export default router

