<template>
  <div class="home">
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  mounted() {
    console.log("this.$router.path",this.$router.path)
    if (!this.$router.path){
      window.location.href =
        "https://xingjiaren-api.hailiangedu.com/app/download";
    }
  },
  methods: {
    goCommonPage(type) {
      this.$router.push({ path: "/commonPage", query: { type: type } });
    },
    sharePage() {
      this.$router.push({
        path: "/sharePage",
        query: { id: "1427930171538128898" },
      });
    },
    pdfViewPage() {
      this.$router.push({ path: "/pdfViewer" });
    },
    detailPage() {
      this.$router.push({ path: "/xinjiaren/detail" });
    },
  },
};
</script>
<style scoped>
.centers {
  text-align: center;
  line-height: 1;
}
.centers-xinjiaren {
  margin-top: 16px;
  text-align: center;
  line-height: 1;
}
</style>
