
<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title"></router-view>
  </div>
</template>
<script>
    window.onload = function() {
    document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    });
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault()
    })
  };
</script>
<style scoped>
body{
  height: 100%;
  width: 100%;
}
#app{
  height: 100%;
  width: 100%;
}
</style>