import Vue from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import App from './App.vue'
import { get, post } from "./axios/axios"
import router from './router'
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)
Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$get = get;
Vue.prototype.$post = post;



Vue.config.ignoredElements = [
  'my-custom-web-component',
  'another-web-component',
  'wx-open-launch-app',
  // 用一个 `RegExp` 忽略所有“ion-”开头的元素
  // 仅在 2.5+ 支持
  /^ion-/
]

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
